<template>
  <v-col cols="12">
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="4">
          <template>
            <label style="color: black" v-if="detail.hasOwnProperty('accreditation_pdf_public_url')">Accreditation PDF</label>
            <br v-if="detail.hasOwnProperty('accreditation_pdf_public_url')">
            <a style="color: blue" target="_blank" v-if="detail.hasOwnProperty('accreditation_pdf_public_url')" :href="detail.accreditation_pdf_public_url">View</a>
            <v-file-input
                accept="application/pdf"
                clearable
                @change="uploadFileInner($event, 'accreditation_pdf', k)"
                label="Upload Accreditation PDF"
                outlined
                :rules="fileRules"
                dense
                v-else
                show-size
            ></v-file-input>
          </template>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              outlined
              dense
              label="Accreditation For"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.accreditation_for">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              outlined
              dense
              label="Accreditation From"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.accreditation_from">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k, detail)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {CLEAR_AUTH_ERRORS, UPLOAD_USER_FILE} from "@/core/services/store/auth.module";
export default {
  name: 'user-accreditation',
  props: ['docOld'],
  data() {
    return {
      details: [
        {accreditation_pdf: '', accreditation_for: '', accreditation_from: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.auth.userAccreditation = v;
      },
      deep: true
    },
    docOld: {
      handler() {
        if (this.docOld.length > 0) {
          this.details = this.docOld
        } else {
          this.details = [
            {accreditation_pdf: '', accreditation_for: '', accreditation_from: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.details = [
    //   {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
    // ]
  },
  methods: {
    add() {
      this.details.push({accreditation_pdf: '', accreditation_for: '', accreditation_from: ''});
    },
    update() {
    },
    remove(index, item) {
      this.$store.state.users.deletedDocuments.push(item);
      this.details.splice(index, 1);
    },
    uploadFileInner(file, col, key) {
      if (file) {
        this.$store.dispatch(CLEAR_AUTH_ERRORS);
        let formData = new FormData()
        formData.append("file", file, file.name)
        this.$store.dispatch(UPLOAD_USER_FILE, {data: formData}).then((data) => {
          this.details[key][col] = data.records
        })
      }
    }
  }
}
</script>