<template>
  <v-col cols="12">
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Organization Name"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.emp_org_name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Position"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.emp_org_position">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Date"
              color="primary"
              type="date"
              @keyup="update"
              v-model="detail.emp_org_date">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Responsibility in Brief"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.emp_org_responsibility">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'user-emp-history',
  props: ['docOld'],
  data() {
    return {
      details: [
        {emp_org_name: '', emp_org_position: '', emp_org_date: '', emp_org_responsibility: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.auth.userEmpHistory = v;
      },
      deep: true
    },
    docOld: {
      handler() {
        if (this.docOld.length > 0) {
          this.details = this.docOld
        } else {
          this.details = [
            {emp_org_name: '', emp_org_position: '', emp_org_date: '', emp_org_responsibility: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.details = [
    //   {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
    // ]
  },
  methods: {
    add() {
      this.details.push({emp_org_name: '', emp_org_position: '', emp_org_date: '', emp_org_responsibility: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
  }
}
</script>